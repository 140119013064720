import { getCurrentUrl } from './Tools';
// import { TagManager } from "./TagManager";

export default class ShareHelper {
  /*
  Usage:
  * Import the tools
    import ShareHelper from 'PATH/TO/JS';
  * On click, call the function
    ShareHelper.facebook();
  * You need to specify the shared text for twitter and linkedin!
    ShareHelper.twitter('This is the text that will be shared');	
   */

  public static twitter(t: string, hashtags?: string[]) {
    let url: string = "https://twitter.com/intent/tweet?text=" + t;
    if (hashtags) {
      url += `&hashtags=${hashtags.join(",")}`;
    }
    url = encodeURI(url);
    this.tagShare("twitter");
    this.share(url);
  }

  static facebook(t?: string) {
    let url: string = `http://www.facebook.com/share.php?u=${encodeURIComponent('https://workwithpact.com')}${t ? `&quote=${t}` : null}`;
    this.tagShare("facebook");
    this.share(url);
  }

  static linkedin(t?: string) {
    let url: string = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${t || document.title}`;
    this.tagShare("linkedin");
    this.share(url);
  }

  static googleplus() {
    let url: string = "https://plus.google.com/share?url=" + window.location.href;
    this.tagShare("googleplus");
    this.share(url);
  }

  static mailto(subject: string, body?: string) {
    let url: string = `mailto:?subject=${subject}`;
    if (body) {
      url += `&body=${body}`;
    }
    this.tagShare("mailto");
    window.open(url, "_blank");
  }

  static clipboard() {
    const el: HTMLTextAreaElement = document.createElement("textarea");
    el.value = window.location.href;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    let msg: string = "The url was copied to your clipboard";
    this.tagShare("clipboard");
    alert(msg);
  }

  public static share(url: string) {
    const w: number = 600;
    const h: number = 400;

    const dualScreenLeft: number = window.screenLeft;
    const dualScreenTop: number = window.screenTop;

    let width: number = 0;
    let height: number = 0;

    if (window.innerWidth) {
      width = window.innerWidth;
    } else if (document.documentElement.clientWidth) {
      width = document.documentElement.clientWidth;
    } else {
      width = window.screen.width;
    }

    if (window.innerHeight) {
      height = window.innerHeight;
    } else if (document.documentElement.clientHeight) {
      height = document.documentElement.clientHeight;
    } else {
      height = window.screen.height;
    }

    let left: number = width / 2 - w / 2 + dualScreenLeft;
    let top: number = height / 2 - h / 2 + dualScreenTop;

    window.open(url, "Share", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=" + w + ",height=" + h + ",top=" + top + ",left=" + left);
  }

  private static tagShare(platform: string) {
    // Skipping 
    return;
  //   const data: GTMEvent = {
  //     event: "userAction",
  //     click: {
  //       category: platform,
  //       action: "social media - share",
  //       label: getCurrentUrl()
  //     }
  //   };
  //   TagManager.push(data);
  // }
  }
};