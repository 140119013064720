import styled from "styled-components";
import { StyledImageGrid } from "../components/ImageGrid/ImageGrid.style";
import { desktopHover, pxtorem } from "../utils/Tools";

export const StyledStory = styled.div`
  .content {
    padding-top: ${pxtorem(100)};
    padding-bottom: ${pxtorem(70)};

    & > div:last-child > p:last-child {
      margin-bottom: 0;
    }

    a {
      color: inherit;

      ${desktopHover(`
        color: var(--aqua);
      `)}
    }



    ${StyledImageGrid} {
      margin: ${pxtorem(100)} 0;

      .caption {
        font-size: ${pxtorem(14)};
        text-transform: none;
        max-width: 80ch;
        margin-inline: auto;
      }

      @media (max-width: 768px) {
        margin: ${pxtorem(60)} 0;
      }
    }

    h2 {
      font-size: ${pxtorem(30)};
      line-height: 1.4;
      text-transform: none;
    }
  }
`;
