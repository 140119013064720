import React, { useContext, useMemo } from "react";
import { Wrapper } from "../../styles/Wrapper.style";
import { PactContext } from "../PactContext";
import { StoryItem, StoryItemData } from "../StoryItem/StoryItem";
import { StyledReadNext } from "./ReadNext.style";

const LABELS = {
  en: "Other Stories You Might Like",
  fr: "Autres Articles Que Vous Pourriez Aimer",
} as const;

export const ReadNext: React.FC<ReadNextProps> = ({ stories }) => {
  const { language } = useContext(PactContext);

  // only allow four stories
  const toShow = useMemo(() => {
    return stories.slice(0, 4);
  }, [stories]);


  return (
    <StyledReadNext>
      <Wrapper className="container">
        <div className="header">
          <h6>{LABELS[language]}</h6>
        </div>
        <div className="stories">
          {toShow.map((story) => {
            return <StoryItem item={story} key={story.slug} />;
          })}
        </div>
      </Wrapper>
    </StyledReadNext>
  );
};

interface ReadNextProps {
  stories: StoryItemData[];
}
