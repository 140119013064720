import React from "react";

export default function LinkedInSVG() {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18H6V6H10V8C10.4211 7.46427 10.9555 7.02837 11.565 6.72356C12.1744 6.41875 12.8438 6.25259 13.525 6.237C14.7164 6.24361 15.8565 6.72242 16.6954 7.56843C17.5343 8.41444 18.0035 9.55859 18 10.75V18H14V11.25C13.9226 10.7096 13.6527 10.2153 13.2398 9.85808C12.8269 9.50088 12.2989 9.30483 11.753 9.306C11.5087 9.31372 11.2684 9.37073 11.0466 9.4736C10.8249 9.57646 10.6262 9.72307 10.4624 9.90462C10.2987 10.0862 10.1734 10.2989 10.0939 10.5301C10.0144 10.7613 9.9825 11.0062 10 11.25V18ZM4 18H0V6H4V18ZM2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
        fill="currentColor"
      />
    </svg>
  );
}
