import styled from "styled-components";
import { pxtorem } from "../../utils/Tools";

export const StyledShareMenu = styled.div`
  width: 100%;
  text-transform: uppercase;
  font: 400 1rem / 120% var(--secondaryFont);
  padding-bottom: ${pxtorem(100)};
  display: flex;
  align-items: center;

  .menu {
    margin: 0 auto;
    min-width: ${pxtorem(230)};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    a, span {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  svg path {
    transition: fill 0.55s cubic-bezier(0.22, 1, 0.36, 1);
  }

  span,
  a {
    text-align: center;
  }

  a {
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  a:hover, a:focus {
    svg path {
      fill: var(--aqua);
    }
  }

  .link {
    &__twitter svg {
      width: 19px;
      height: 16px;
    }
    
    &__facebook svg {
      width: 20px;
      height: 20px;
    }
    
    &__linkedin {
      height: 18px;
      width: 18px;
    }
    
    &__mail {
      width: 19px;
      height: 15px;
    }
  }
`;
