import React, { useContext, useMemo, useRef } from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {
  generateReactFromMarkdown,
  removeNewLineFromReactComponent,
} from "../cms/cms-components";
import { Page } from "../components/Page";
import { Section } from "../styles/Section.style";
import { getLocaleMdData } from "../utils/Tools";
import { ImageHeroBanner } from "../components/ImageHeroBanner/ImageHeroBanner";
import themeObj from "../styles/theme";
import { SEO, SEOProps } from "../components/SEO/SEO";
import { StyledStory } from "./story.style";
import { ShareMenu } from "../components/ShareMenu/ShareMenu";
import { StoryItemData } from "../components/StoryItem/StoryItem";
import { ReadNext } from "../components/ReadNext/ReadNext";

const SCROLL_TITLES = {
  en: "Continue Reading",
  fr: "Continuer la Lecture",
} as const;

const Story = ({ data, pageContext }: CaseStudyProps) => {
  //#region Variables
  const { allStories } = data;

  const _data = getLocaleMdData(data);

  const { language, slugs } = pageContext;

  const linkedImages = data.markdownRemark?.frontmatter.linkedFiles ?? [];

  const { theme, seo, content, featuredImage, smallDescription, category } =
    _data[language];

  const hasFrenchContent = _data["fr"].content !== _data["en"].content;

  const globalSEO: SEOProps =
    (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title: seoTitle, description, ogImage } = (seo ? seo[0] : null) || {};

  const title = language === "en" ? _data.en.title : _data.fr.titleFR;

  const cursorColor = themeObj[theme || "pebble"].cursor;
  //#endregion

  // const themeContext = useContext(ThemeContext);
  //#region Functions
  const onContinueReadingClicked = (e: React.MouseEvent) => {
    e.preventDefault();
    if (contentRef.current) {
      contentRef.current.scrollIntoView({behavior: "smooth"});
    }
  }
  //#endregion
  //#region Hooks
  const contentRef = useRef<HTMLDivElement>();
  
  const readNextStories = useMemo<StoryItemData[]>(() => {
    // sort by weight, filter out the current story, and return appropriate lang
    const niceData = allStories.nodes.map(({ fields }) => {
      if (fields.locales[language]) {
        return fields.locales[language];
      }
      return fields.locales["en"];
    }) as StoryItemData[];

    const sortedData = niceData.filter(({showInListing}) => showInListing).sort((a, b) => b.weight - a.weight);

    const withoutSelf = sortedData.filter((story) => {
      return story.slug !== slugs[language].split("/").slice(-1)[0];
    });

    return withoutSelf;
  }, [allStories, language]);
  //#endregion

  return (
    <Page
      background="charcoal"
      language={language}
      slugs={slugs}
      showMissingFrenchBanner={language === "fr" && !hasFrenchContent}
      overlapHeader
    >
      <StyledStory>
        <SEO
          title={seoTitle ? seoTitle : title}
          description={description ? description : globalSEO.description}
          ogImage={ogImage ? ogImage : globalSEO.ogImage}
        />
        <Section
          className={`theme-${theme || "pebble"}`}
          bg={theme || "pebble"}
          data-cursor-color={cursorColor}
        >
          <ImageHeroBanner
            title={title}
            image={featuredImage}
            scrollTitle={SCROLL_TITLES[language]}
            category={category}
            smallDescription={smallDescription}
            layout="split"
            onScrollClick={onContinueReadingClicked}
          />
          <div className="content" ref={contentRef}>
            <ReactMarkdown
              components={{ ...generateReactFromMarkdown(linkedImages) }}
              // transformImageUri={(src: string) => isPreview ? src.split('../static/').join('/') : src}
              rehypePlugins={[rehypeRaw]}
            >
              {removeNewLineFromReactComponent(content)}
            </ReactMarkdown>
          </div>
          <div className="share-menu">
            <ShareMenu title={title} />
          </div>
          <ReadNext stories={readNextStories} />
        </Section>
      </StyledStory>
    </Page>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    globalSEO: markdownRemark(fileAbsolutePath: { regex: "/misc/seo.md/" }) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
            theme
            slug
            title
            titleFR
            category
            smallDescription
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 3840
                  placeholder: DOMINANT_COLOR
                  transformOptions: { fit: COVER }
                )
              }
            }
            content
          }
          fr {
            ...SeoFragmentFR
            theme
            slug
            title
            category
            titleFR
            smallDescription
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 3840
                  placeholder: DOMINANT_COLOR
                  transformOptions: { fit: COVER }
                )
              }
            }
            content
          }
        }
      }
      frontmatter {
        linkedFiles {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
          imageGrid: childImageSharp {
            gatsbyImageData(width: 3326, placeholder: BLURRED)
          }
          quoteBanner: childImageSharp {
            gatsbyImageData(width: 118, placeholder: BLURRED)
          }
          sideBySide: childImageSharp {
            gatsbyImageData(
              width: 1640
              placeholder: BLURRED
              transformOptions: { fit: COVER }
            )
          }
          fullWidthCarousel: childImageSharp {
            gatsbyImageData(
              width: 2408
              placeholder: DOMINANT_COLOR
              transformOptions: { fit: COVER }
            )
          }
          logoListing: childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
    allStories: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "story" } } }
    ) {
      nodes {
        fields {
          locales {
            en {
              title
              weight
              showInListing
              category
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 1922
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
            fr {
              title
              weight
              showInListing
              category
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 1922
                    placeholder: DOMINANT_COLOR
                    transformOptions: { fit: COVER }
                  )
                }
              }
              smallDescription
            }
          }
        }
      }
    }
  }
`;

interface CaseStudyProps {
  pageContext: {
    id: string;
    language: lang;
    slugs: { en: string; fr: string };
  };
  data: LocalizedAllMarkdownDataRelation<StoryData>["data"];
}

interface StoryData {
  theme: ThemeBG;
  seo: SEOProps[];
  slug: string;
  title: string;
  titleFR: string;
  featuredImage: any;
  smallDescription: string;
  content: string;
  linkedFiles: any[];
  category: string;
  allStories: LocalizedAllMarkdownDataRelation<StoryItemData[]>;
}

export default Story;
