import React from 'react';

export default function TwitterSVG() {
  return (
    <svg
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.633 3.99704C17.646 4.17204 17.646 4.34604 17.646 4.52004C17.646 9.84504 13.593 15.981 6.186 15.981C3.904 15.981 1.784 15.32 0 14.172C0.324 14.209 0.636 14.222 0.973 14.222C2.78599 14.2264 4.54765 13.6202 5.974 12.501C5.13342 12.4858 4.31858 12.2085 3.64324 11.7078C2.9679 11.2071 2.46578 10.5079 2.207 9.70804C2.456 9.74504 2.706 9.77004 2.968 9.77004C3.329 9.77004 3.692 9.72004 4.029 9.63304C3.11676 9.44886 2.29647 8.95439 1.70762 8.23373C1.11876 7.51306 0.797693 6.61069 0.799 5.68004V5.63004C1.336 5.92904 1.959 6.11604 2.619 6.14104C2.06609 5.77363 1.61272 5.27507 1.29934 4.68983C0.98596 4.1046 0.822313 3.4509 0.823 2.78704C0.823 2.03904 1.022 1.35304 1.371 0.755044C2.38314 2.00006 3.6455 3.01861 5.07634 3.7447C6.50717 4.4708 8.07456 4.88826 9.677 4.97004C9.615 4.67004 9.577 4.35904 9.577 4.04704C9.57674 3.518 9.68074 2.9941 9.88308 2.50528C10.0854 2.01647 10.3821 1.57232 10.7562 1.19823C11.1303 0.824145 11.5744 0.527454 12.0632 0.325121C12.5521 0.122789 13.076 0.0187806 13.605 0.0190435C14.765 0.0190435 15.812 0.505043 16.548 1.29104C17.4498 1.11666 18.3145 0.787465 19.104 0.318043C18.8034 1.24886 18.1738 2.03815 17.333 2.53804C18.1328 2.44682 18.9144 2.2365 19.652 1.91404C19.1011 2.71714 18.4185 3.42139 17.633 3.99704V3.99704Z"
        fill="currentColor"
      />
    </svg>
  );
}
