import React, { useContext } from "react";
import FacebookSVG from "../../styles/svg/Facebook.svg";
import LinkedInSVG from "../../styles/svg/LinkedIn.svg";
import MailSVG from "../../styles/svg/Mail.svg";
import TwitterSVG from "../../styles/svg/Twitter.svg";
import ShareHelper from "../../utils/ShareHelper";
import { PactContext } from "../PactContext";
import { StyledShareMenu } from "./ShareMenu.style";

const LABELS = {
  en: "Share",
  fr: "Partager",
} as const;

function mailSubjects(language: keyof typeof LABELS, title: string) {
  if (language === "en") {
    return `Check out ${title} from Pact!`
  }
  return `Lisez l'article ${title} de Pact!`
}

export const ShareMenu: React.FC<ShareMenuProps> = ({ title }) => {
  const { language } = useContext(PactContext);

  const onTwitterShare = (e: React.MouseEvent) => {
    e.preventDefault();
    ShareHelper.twitter(title);
  };

  const onFacebookShare = (e: React.MouseEvent) => {
    e.preventDefault();
    ShareHelper.facebook(title);
  };

  const onLinkedInShare = (e: React.MouseEvent) => {
    e.preventDefault();
    ShareHelper.linkedin(title);
  };

  const onMailShare = (e: React.MouseEvent) => {
    e.preventDefault();
    ShareHelper.mailto(mailSubjects(language, title), window.location.href);
  };

  return (
    <StyledShareMenu>
      <div className="menu">
        <span>{LABELS[language]}</span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={onMailShare}
          className="link__mail"
        >
          <MailSVG />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={onFacebookShare}
          className="link__facebook"
        >
          <FacebookSVG />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={onLinkedInShare}
          className="link__linkedin"
        >
          <LinkedInSVG />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={onTwitterShare}
          className="link__twitter"
        >
          <TwitterSVG />
        </a>
      </div>
    </StyledShareMenu>
  );
};

interface ShareMenuProps {
  title: string;
}
