import styled from 'styled-components';
import { pxtorem } from '../../utils/Tools';

export const StyledReadNext = styled.div`
  .header {
    text-align: center;
    border-top: 1px solid #CFCDCB;

    h6 {
      font: 400 ${pxtorem(48)} / 120% var(--primaryFont);
    }
  }

  .stories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: calc(100% - var(--gutter))
    margin: 0 auto;
    padding-bottom: ${pxtorem(200)};
    grid-gap: 20px;

    @media(max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      grid-gap: 48px;
    }
  }
`;